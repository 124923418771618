import JoeRomeo from '../images/joe-romeo-linkedin.jpg'
import NickRomeo from '../images/nick-romeo-linkedin.jpg'
import TomVecchione from '../images/Tom-Vecchione .jpeg'
import BarbaraBurke from '../images/Barbara-Burke.png'
import TrangDoan from '../images/Trang-Doan.png'
import TahverleeAnglen from '../images/Tahverlee-Anglen.png'
import JarrydNelson from '../images/Jarryd-Nelson.png'
import RebeccaNagy from '../images/Rebecca-Nagy.png'

const testimonials = [
    {
        name: "Nick Romeo",
        thumbnailURL: NickRomeo,
        content: "Taylor is great to work with and very responsive.  We came to him with a rush project for a very important client.  Taylor delivered high-quality work UNDER the projected timeline.",
        ratings: "5"
    },
    {
        name: "Joe Romeo",
        thumbnailURL: JoeRomeo,
        content: "Taylor is a true professional and easy to work with.  We have contacted him on two jobs and he won the bid on the second.  After working with his responsiveness and his ideas I wish we had given him the first bid. I did my fair share of production when I was in Los Angeles and would tell you his ideas and delivery time is right up there with the best",
        ratings: "5"
    },
    {
        name:"Tom Vecchione",
        thumbnailURL: TomVecchione,
        content: "I contracted with Taylor to create an engaging and clean web site for my new business. Taylor provided outstanding customer support throughout the process. He was exceptionally prompt and detailed in responding to my many questions. The new web site is just what I had in mind. He was very easy to work with and made it very easy for me. I give my highest recommend to Janszen Media and Taylor!!",
        ratings: "5"
    },
    {
        name: "Barbara Burke",
        thumbnailURL: BarbaraBurke,
        content: "Taylor Janszen of Janszen Media is incredibly talented. I was referred to Taylor when I was seeking a web designer to build my website from scratch. I had a  vision of how I wanted it to look and feel and Taylor brought that vision to life. He is an excellent collaborator and can convert a wish list into a reality. Taylor even managed communications with my web hosting company on my behalf during this process. Taylor is fantastic and I highly recommend him for his creativity and deep knowledge of web design. He gets to know you and is truly invested in your success.",
        ratings: "5"
    },
    {
        name: "Trang Doan",
        thumbnailURL: TrangDoan,
        content: "Taylor and his team are amazing! They went above and beyond my expectations in developing my website. Taylor was helpful and gave excellent advice on how I should market myself and my business. He was also accommodating to my schedule changes and was willing to help whenever I had any questions. The website he created for me is professional, yet enticing. It offers all the features I wanted and more. I couldn't have asked for a better quality product, especially at his competitive rates. The best part of it all, he developed the website in such a way that I can easily edit myself after it goes live.",
        ratings: "5"
    },
    {
        name: "Tahverlee Anglen",
        thumbnailURL: TahverleeAnglen,
        content: "Janszen Media stepped in at a time when I was transitioning my site to something completely different.  They took my vision and created a state of the art design and flow that was perfect.  Highly recommend!",
        ratings: "5"
    },
    {
        name: "Jarryd Nelson",
        thumbnailURL: JarrydNelson,
        content: "Janszen Media did an amazing job building our companies new website. Taylor is a great communicator and took the time to fully understand our business and our needs every step of the way. I would highly recommend Taylor and Janszen Media to anyone!",
        ratings: "5"
    },
    {
        name: "Rebecca Nagy",
        thumbnailURL: RebeccaNagy,
        content: "Janszen Media created our amazing website that is not only professional and state-of-the art, but also customer friendly. Taylor's  knowledge of SEO and digital marketing along with excellent customer service are unmatched. Taylor gives attention to detail and excellence and truly cares about the companies that he works with. Thank you Taylor and Janszen Media for a great experience. I couldn't recommend you more!",
        ratings: "5"
    }
]

export {testimonials};