import React from 'react'
import "./index.css"
import { Nav } from 'react-bootstrap';
import { menuItems } from '../../constance/menu.constant';

const Menu = () => {

  const scrollIntoViewWithOffset = (selector, offset) => {
    window.scrollTo({
      behavior: "smooth",
      top:
        document.querySelector(selector).getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        offset,
    });
  };

  return (
    <Nav className="menu">
      {menuItems?.map((item) => (
        <Nav.Link
          as="span"
          key={item.label}
          className="text-uppercase text-white px-3 menu-item animate__animated animate__fadeIn"
          onClick={() => scrollIntoViewWithOffset(item.link, 60)}
        >
          {item.label}
        </Nav.Link>
      ))}
    </Nav>
  );
}

export default Menu