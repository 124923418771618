const professionalSkills = [
    "10+ Years in Leadership",
    "7+ Years in Business",
    "Social Media",
    "Graphic Design",
    "Digital Marketing",
    "Photoshop",
    "Online Advertising",
    "Social Media Marketing",
    "Google Analytics",
    "Microsoft Excel",
    "Video Editing",
    "Data Analysis",
    "Content Marketing",
    "Email Marketing",
    "Paid Advertising",
    "Marketing Automation",
    "Budget Management",
    "Competitor Analysis",
    "CRM Integration",
    "Project Management"
]

export {
    professionalSkills
}