import React from "react";
import { Col, Container, Image, Nav, Row } from "react-bootstrap";
import Widget from "./widget";

import Logo from "../../images/taylor_janszen.png"
import JanszenMedia from "../../images/Janszen-Media-Logo.png"
import Networking3For3 from "../../images/3For3-Networking-Logo-small.png";
import SocialIconList from "../socialIconList";
import {
  TaylorJanszenSocialMedia,
  JanszenMediaSocialMedia,
  NetworkingSocialMedia,
} from "../../constance/socialMedia";
import { AnimationOnScroll } from "react-animation-on-scroll";
// import { footerMenu1 } from "../../constance/footer.constant";
// import { menuItems } from "../../constance/menu.constant";

const Footer = () => {
  return (
    <Container fluid className="py-5 main-lighter-bg-color" id="footer">
      <Container className="my-2 my-lg-4">
        <Row>
          <Widget colSize={4} className="mb-4 mb-lg-0 text-center">
            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
              <Image
                src={Logo}
                alt="Taylor Janszen"
                fluid
                style={{ marginTop: 15, marginBottom: 8, width: 300 }}
              />
            </AnimationOnScroll>

            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
              <SocialIconList
                className="justify-content-center"
                SocialMedia={TaylorJanszenSocialMedia}
              />
            </AnimationOnScroll>

            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
              <p className="mt-4">
                I am a business owner, idea innovator, fractional CMO
                consultant, digital marketer, web design pro, graphic designer,
                analytics expert and professional photographer and videographer.
              </p>
            </AnimationOnScroll>
          </Widget>

          <Widget colSize={4} className="mb-4 mb-lg-0 text-center">
            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
              <a
                href="https://www.janszenmedia.com/"
                target="_blank"
                className="d-inline-block"
              >
                <Image
                  src={JanszenMedia}
                  alt="Janszen Media LLc."
                  fluid
                  style={{ marginTop: 25 }}
                  className="d-block"
                />
              </a>
            </AnimationOnScroll>

            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
              <SocialIconList
                className="justify-content-center"
                SocialMedia={JanszenMediaSocialMedia}
              />
            </AnimationOnScroll>

            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
              <p className="mt-4">
                Serving the Columbus, Ohio area, Janszen Media is a website
                design and digital marketing agency. We're looking to excite
                entrepreneurs like you and take your business goals to the next
                level.
              </p>
            </AnimationOnScroll>
          </Widget>

          <Widget colSize={4} className="text-center">
            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
              <a
                href="https://www.3for3networking.com/"
                target="_blank"
                className="d-inline-block"
              >
                <Image
                  src={Networking3For3}
                  alt="3For3 Networking"
                  fluid
                  style={{ marginTop: 25, width: 270 }}
                  className="d-block"
                />
              </a>
            </AnimationOnScroll>

            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
              <SocialIconList
                className="justify-content-center"
                SocialMedia={NetworkingSocialMedia}
              />
            </AnimationOnScroll>

            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
              <p className="mt-4">
                3For3 Networking is a business mentorship and networking group.
                We have designed the platform for professionals, business owners
                and students to build and network through digital technology.
              </p>
            </AnimationOnScroll>
          </Widget>
        </Row>
      </Container>

      <Container className="pt-3 pt-lg-5">
        <Row>
          <Col xs={12} className="text-center">
            <p className="fs-6 main-color m-0">
              &copy; {new Date().getFullYear()} copyright all rights reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Footer;
