import React from 'react'
import "./index.css"
import { Container, Image, Navbar } from 'react-bootstrap';

import Logo from "../../images/logo.png"
import Menu from '../menu';

const Header = () => {
  return (
    <Navbar
      fixed="top"
      collapseOnSelect
      expand="lg"
      className="main-bg-color py-lg-0"
    >
      <Container>
        <Navbar.Brand href="#home" className="me-auto p-0">
          <Image
            src={Logo}
            fluid
            className="logo animate__animated animate__fadeIn"
          />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="p-0 border-0 toggle_btn"
        >
          <span className="d-flex menu-btn">
            <span>Menu</span>
            <span className="menu-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </span>
        </Navbar.Toggle>
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-end"
        >
          <Menu />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header