import React from 'react'
import { Container, Image, Row } from 'react-bootstrap'
import { AnimationOnScroll } from "react-animation-on-scroll";
import SquareBox from '../components/squarebox'

import { whatido } from '../constance/whatido.constant'

import CurveLine from "../images/curve_line.png"
import CurlyLines from "../images/green_curly_lines.png"
import MultiCircles from "../images/multi_circle_vector.png"

const WhatIdo = () => {
  return (
    <>
      <Container fluid className="py-5" id="what-i-do">
        <Container className="position-relative z-1">
          <Image
            src={CurlyLines}
            className="position-absolute"
            style={{ left: -80, top: -30 }}
          />
          <Row className="my-2 my-lg-5 z-1">
            {whatido?.map((item) => (
              <SquareBox key={item.title} item={item} className="my-2 my-lg-4" />
            ))}
          </Row>
          <AnimationOnScroll animateIn="animate__fadeIn">
            <Image
              src={MultiCircles}
              className="position-absolute"
              style={{ right: -110, bottom: 0 }}
            />
          </AnimationOnScroll>
        </Container>
      </Container>
      <Container fluid>
        <Container className="position-relative">
          <AnimationOnScroll animateIn="animate__fadeIn">
            <Image
              src={CurveLine}
              className="position-absolute"
              style={{ left: -110, bottom: 0, zIndex: 0 }}
            />
          </AnimationOnScroll>
        </Container>
      </Container>
    </>
  );
}

export default WhatIdo