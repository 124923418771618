const slider = {
    title: "HELLO!",
    subTitle: "I'M Taylor Janszen",
    content: "Digital Marketing Guru and Website Design Expert.",
    ctabutton: {
        label: "Schedule a Call",
        link: "#getintouch"
    }
}

const infoBoxes = [
    {
        title: "INTEGRITY",
        content: "Honest with strong moral principles and always doing the right thing. Transparent, ethical and trustworthy."
    },
    {
        title: "CREATIVE",
        content: "Always creating something, developing, innovating, and imagining new ideas while thinking outside the box."
    },
    {
        title: "ADVENTURE",
        content: "Seeking excitement, new experiences, and challenges in life and business. A journey for new opportunities."
    }
];

export { slider, infoBoxes }