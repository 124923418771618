import Facebook from "../images/facebook_logo_icon.svg"
import Instagram from "../images/instagram_logo_icon.svg"
import TwitterX from "../images/twitter_logo_icon.svg"
import Linkedin from "../images/linkedin_logo_icon.svg"
import YouTube from "../images/youtube_icon.svg"
import Vimeo from "../images/vimeo_icon.svg"

const TaylorJanszenSocialMedia = [
    {
        icon: Facebook,
        link: "https://facebook.com/taylor.janszen/"
    },
    {
        icon: Instagram,
        link: "https://www.instagram.com/taylorjanszen/?hl=en"
    },
    {
        icon: TwitterX,
        link: "https://twitter.com/taylorjanszen"
    },
    {
        icon: Linkedin,
        link: "https://www.linkedin.com/in/taylor-janszen/?challengeSource=AgHBA2sNYv7k3AAAAYqoT5mccrJf1zIFrJcxAdH26EmDSWMNUdeTdnfiQjNmSXY&challegeType=AgHfp65_iCn9rQAAAYqoT5mf9UXEMrTjuT-BAfdjEg_XqPsFbF3C6eM&memberId=AgG1wwEo16PxkgAAAYqoT5mhC-_v92Vwua0DBL0wLoeg2M4&recognizeDevice=AgHVIdZUxilaJwAAAYqoT5mkji0jt4eLhCKXWSKmGM4bKlzS0bvf&challengeId=AQEFNgHDH7E8pgAAAYqw2TlWa1pAowyqukYDetc_3m0nQFDj5bVfwXyF8yvC0x5HeBDLbBu8BO9hinjpjy2eBBuM1qKiMQuG7Q&submissionId=717cee7b-fe80-8617-3865-1da6173ff75e&challengeSource=AgHqGQrbQVGwHgAAAYqw2Xa_uzGJTMeZa81fIldd59FffzVgJUYqfJiGpo_qHWg&challegeType=AgHOAXZcnYXVDgAAAYqw2XbCtBp_MdIeoiJGjJoE_E-o2pQqldNckc0&memberId=AgEhN4UeOdPdYQAAAYqw2XbFP77PpYlX8DqLeaues9ED1G8&recognizeDevice=AgHRUfFPTtEQ4QAAAYqw2XbIO2m4PDXs2Rk6o7i4LHeiZIFAoXdG"
    },
    {
        icon: YouTube,
        link: "https://www.youtube.com/user/TJanszen5"
    },
    {
        icon: Vimeo,
        link: "https://vimeo.com/tayorjanszen"
    }
]

const JanszenMediaSocialMedia = [
    {
        icon: Facebook,
        link: "https://www.facebook.com/Janszen-Media-147797535817577"
    },
    {
        icon: Instagram,
        link: "https://www.instagram.com/janszenmedia/"
    },
    {
        icon: TwitterX,
        link: "https://twitter.com/JanszenMedia"
    },
    {
        icon: Linkedin,
        link: "https://www.linkedin.com/company/janszen-media"
    },
    {
        icon: YouTube,
        link: "https://www.youtube.com/@janszenmedia"
    },
    {
        icon: Vimeo,
        link: "https://vimeo.com/442411577"
    }
]

const NetworkingSocialMedia = [
    {
        icon: Facebook,
        link: "#"
    },
    {
        icon: Instagram,
        link: "#"
    },
    {
        icon: TwitterX,
        link: "#"
    },
    {
        icon: Linkedin,
        link: "#"
    },
    {
        icon: YouTube,
        link: "#"
    },
    {
        icon: Vimeo,
        link: "#"
    }
]

export { TaylorJanszenSocialMedia, JanszenMediaSocialMedia, NetworkingSocialMedia }