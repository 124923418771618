import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { AnimationOnScroll } from "react-animation-on-scroll";
import { professionalSkills } from '../constance/professionalSkills.constant';

import CircleLines from "../images/half_circle_lines.png"

const ProfessionalSkill = () => {

  const SkillsItem = ({item, className}) => {
    return (
      <li className={`d-flex align-items-center py-3 ${className}`}>
        <span className="rounded-circle p-3 main-bg-color d-none"></span>
        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
          <span className="">{item}</span>
        </AnimationOnScroll>
        <div className="skill_border"></div>
      </li>
    );
  }

  return (
    <>
      <Container fluid className="py-5" id="professional-skills">
        <Container className="my-4 my-lg-5">
          <Row>
            <Col xs={12} className="text-center">
              <AnimationOnScroll
                animateIn="animate__fadeInDown"
                animateOnce={true}
              >
                <h2 className="h1 main-color">Professional Skills</h2>
              </AnimationOnScroll>
            </Col>
          </Row>

          <Row className="pt-3 pt-lg-5">
            <Col xs={12}>
              <ul className="d-flex flex-wrap p-0 m-0">
                {professionalSkills?.map((item, index) => (
                  <SkillsItem
                    key={index}
                    item={item}
                    className="skills_item main-color"
                  />
                ))}
              </ul>
            </Col>
          </Row>
        </Container>
      </Container>
      {/* Vector Icon  */}
      <Container fluid>
        <Container className="position-relative">
          <AnimationOnScroll animateIn="animate__fadeIn">
            <Image
              src={CircleLines}
              className="position-absolute"
              style={{ right: -80, bottom: 0 }}
            />
          </AnimationOnScroll>
        </Container>
      </Container>
    </>
  );
}

export default ProfessionalSkill