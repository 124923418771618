import React from 'react'
import "./index.css"
import { Card, Col } from 'react-bootstrap';
import { AnimationOnScroll } from "react-animation-on-scroll";

const SquareBox = ({item, className, animateElement=true}) => {
  return (
    <Col key={item?.title} xs={12} md={4} className={`square_box ${className}`}>
      {animateElement ? (
        <AnimationOnScroll animateIn="animate__fadeIn">
          <Card className="text-center bg-white hero__whitebox mx-xl-3 my-2 my-xl-0">
            <Card.Body>
              <h2 className="h5 fw-bold title">{item?.title}</h2>
              <p className="m-0 content">{item?.content}</p>
            </Card.Body>
          </Card>
        </AnimationOnScroll>
      ) : (
        <Card className="text-center bg-white hero__whitebox mx-xl-3 my-2 my-xl-0">
          <Card.Body>
            <h2 className="h5 fw-bold title">{item?.title}</h2>
            <p className="m-0 content">{item?.content}</p>
          </Card.Body>
        </Card>
      )}
    </Col>
  );
}

export default SquareBox;