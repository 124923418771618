import TaylorJanszen from "../images/taylor_janszen_about.png"
import TaylorSignature from "../images/taylor-janszen-script.png"

const aboutme = {
    title: "About Me",
    content: `<p class="fs-5 lh-base m-0" style="text-align: justify;">
              <span class="d-block main-color">
                "I'm learning something new everyday."
              </span>
              I am a business owner, idea innovator, fractional CMO consultant, digital marketer, web design pro, graphic designer, analytics expert and professional photographer and videographer. I love a challenge and developing new ideas and ways of thinking to grow business sales, leads, profits, business practices, processes, systems and more! I thrive in an ever changing environment and look forward to meeting new and inspiring people that can help me grow and succeed everyday.
            </p><p class="mt-4 mb-0 d-flex flex-column"><img src="${TaylorSignature}" class="img-fluid mb-2" style="width: 250px" /><small class="fs-6 fw-bold">CEO of Janszen Media, LLC.</small></p>`
}

const aboutThumbnail = {
  url: TaylorJanszen,
  alt: "Taylor Janszen"
}

export { aboutme, aboutThumbnail }