const menuItems = [
      {
        label: "Home",
        link: "#home",
      },
      {
        label: "About",
        link: "#about",
      },
      {
        label: "What I Do",
        link: "#what-i-do",
      },
      {
        label: "Testimonials",
        link: "#testimonial",
      },
      {
        label: "Contact",
        link: "#getintouch",
      },
    ];

export { menuItems }