import React, { useRef } from 'react'
import "./index.css"
import { Button, Col, Container, Image, Row } from 'react-bootstrap'

import { slider, infoBoxes } from "../../constance/hero.constant"

import LogoTick from "../../images/logo-tick.png"
import Taylor from "../../images/taylor-janszen.png"
import CurlyWave from "../../images/green_curly_lines.png"

import SquareBox from '../squarebox'
import { AnimationOnScroll } from 'react-animation-on-scroll'

const Hero = ({id}) => {
  return (
    <Container fluid className="hero__slider" id={id}>
      <Container className="position-relative">
        <Image
          src={CurlyWave}
          className="position-absolute animate__animated animate__fadeInRight"
          style={{
            right: -80,
            top: 50,
          }}
        />
        <Row>
          <Col lg={5} className="position-relative text-center">
            <Image
              src={LogoTick}
              fluid
              className="hero__logotick animate__animated animate__fadeInDown"
            />
            <Image
              src={Taylor}
              fluid
              className="hero__taylor_img animate__animated animate__fadeInLeft"
            />
          </Col>
          <Col lg={7} className="hero_text_container text-center">
            <h1 className="hero__title main-color mb-0 animate__animated animate__fadeInDown">
              {slider.title}
            </h1>
            <h2 className="hero__subtitle main-color mb-0 animate__animated animate__fadeInDown">
              I'M Taylor Janszen
            </h2>
            <p className="hero__intro animate__animated animate__fadeInDown">
              Digital Marketing Guru and Website Design Expert.
            </p>

            {slider.ctabutton?.label && slider?.ctabutton?.link ? (
              <Button
                variant="primary"
                as="a"
                href={slider?.ctabutton?.link}
                size="lg"
                className="px-5 rounded-5 main-bg-color border mt-4 hero__ctabutton animate__animated animate__fadeInDown"
              >
                {slider.ctabutton?.label}
              </Button>
            ) : null}
          </Col>
        </Row>

        {/* Info boxes */}
        <Row>
          {infoBoxes?.map((item, index) => (
            <SquareBox
              key={index}
              item={item}
              animateElement={false}
              className="animate__animated animate__fadeInUp"
            />
          ))}
        </Row>
      </Container>
    </Container>
  );
}

export default Hero