import './App.css';
import 'animate.css';
import Footer from './components/footer';
import Header from './components/header';
import About from './sections/About';
import Education from './sections/Education';
import GetInTouch from './sections/GetInTouch';
import Home from './sections/Home';
import JobExperience from './sections/JobExperience';
import ProfessionalSkill from './sections/ProfessionalSkill';
import Testimonials from './sections/Testimonials';
import WhatIdo from './sections/WhatIdo';

function App() {
  return (
    <div className="App">
      <Header />
      <Home />
      <About />
      <WhatIdo />
      <ProfessionalSkill />
      <JobExperience />
      <Education />
      <Testimonials />
      <GetInTouch />
      <Footer />
    </div>
  );
}

export default App;
