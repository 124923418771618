const whatido = [
    {
        title: "BUSINESS STRATEGY",
        content: "Helping you optimize your business processes to maximize profitability and eliminate unnecessary costs."
    },
    {
        title: "WEBSITE DESIGN",
        content: "Professionally designed and developed to function and convert leads into sales. Be a leader in your industry."
    },
    {
        title: "SEO OPTIMIZATION",
        content: "Your website ranking really matters. SEO Services will help you get to the top of the ranks and stay there!"
    },
    {
        title: "GRAPHIC DESIGN",
        content: "Your own personalized designer. Get website graphics, biz card and flyers designs, social media and more."
    },
    {
        title: "BUSINESS CONSULTING",
        content: "Monthly Ideas, recommendations, strategies, and implementation of digital solutions, email/sms marketing."
    },
    {
        title: "DIGITAL MARKETING",
        content: "Ability to manage powerful campaigns, build transparent reporting and solutions to grow online visibility."
    }
]

export { whatido }