import React, { useRef } from 'react'
import { Card, Col, Container, Image, Row } from 'react-bootstrap'
import { AnimationOnScroll } from "react-animation-on-scroll"

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import Arrow from "../images/arrow.png"
import Stars from "../images/stars_icons.png"
import CircleLines from "../images/half_circle_lines.png";
import CurlyLines from "../images/green_curly_lines.png";
import { testimonials } from '../constance/testimonials.constant';

const Testimonials = () => {

  const carouselRef = useRef(null);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
      partialVisibilityGutter: 30,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      partialVisibilityGutter: 30,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 30,
    },
  };

  const onClickLeft = () => {
    if (carouselRef.current) {
      carouselRef.current.previous();
    }
  };

  const onClickRight = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
      console.log(carouselRef.current);
    }
  };

  const CustomArrowGroup = ({ onClickLeft, onClickRight }) => (
    <div className="custom-arrow-group">
      <div className="custom-arrow custom-left-arrow" onClick={onClickLeft}>
        <Image src={Arrow} alt="Arrow" fluid />
      </div>
      <div className="custom-arrow custom-right-arrow" onClick={onClickRight}>
        <Image
          src={Arrow}
          alt="Arrow"
          fluid
          style={{ transform: "rotate(-180deg)" }}
        />
      </div>
    </div>
  );

  const trimmedContent = (string) => {
    // return string?.length > 200 ? string?.substring(0, 200 - 10) + "..." : string;
    return string;
  }


  const TestimonialItem = ({ name, thumbnailURL, content, ratings }) => {
    return (
      <Card style={{ textAlign: "center" }}>
        {thumbnailURL && (
          <Card.Img variant="top" src={thumbnailURL} className="thumbail" />
        )}
        <Card.Body>
          {content && <Card.Text>{trimmedContent(content)}</Card.Text>}
          {name && <Card.Title>{name}</Card.Title>}
          {ratings && (
            <Card.Title>
              <Image src={Stars} fluid width={100} />
            </Card.Title>
          )}
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      <Container fluid>
        <Container className="position-relative">
          <AnimationOnScroll animateIn="animate__fadeIn">
            <Image
              src={CurlyLines}
              className="position-absolute"
              style={{ left: -80, top: 50 }}
            />
          </AnimationOnScroll>
        </Container>
      </Container>
      <Container fluid className="py-5" id="testimonial">
        <Container className="my-4 my-lg-5">
          <Row>
            <Col xs={12} className="text-center">
              <AnimationOnScroll
                animateIn="animate__fadeInDown"
                animateOnce={true}
              >
                <h2 className="h1 main-color">Testimonials</h2>
              </AnimationOnScroll>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col xs={12}>
              <AnimationOnScroll animateIn="animate__fadeIn">
                <div className="position-relative testimonials_carousel">
                  <Carousel
                    ref={carouselRef}
                    additionalTransfrom={0}
                    arrows={false}
                    autoPlay
                    autoPlaySpeed={5000}
                    centerMode={false}
                    className=""
                    containerClass="testimonials-container-with-dots"
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    infinite={true}
                    itemClass="testimonials-items"
                    keyBoardControl
                    minimumTouchDrag={80}
                    pauseOnHover
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside
                    renderDotsOutside={false}
                    responsive={responsive}
                    customButtonGroup={
                      <CustomArrowGroup
                        onClickLeft={onClickLeft}
                        onClickRight={onClickRight}
                      />
                    }
                  >
                    {testimonials?.map((item, index) => (
                      <TestimonialItem
                        key={`${item?.name}-${index}`}
                        name={item?.name}
                        thumbnailURL={item?.thumbnailURL}
                        content={item?.content}
                        ratings={item?.ratings}
                      />
                    ))}
                  </Carousel>
                </div>
              </AnimationOnScroll>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid>
        <Container className="position-relative">
          <AnimationOnScroll animateIn="animate__fadeIn">
            <Image
              src={CircleLines}
              className="position-absolute"
              style={{ right: -80, bottom: 0 }}
            />
          </AnimationOnScroll>
        </Container>
      </Container>
    </>
  );
}

export default Testimonials