import React, { useState } from 'react'
import { Card, Col, Container, Image, Row } from 'react-bootstrap'
import { AnimationOnScroll } from "react-animation-on-scroll";
import { jobExperience } from '../constance/jobExperience.constant';
import JanszenMediaFavicon from "../images/janszen-media-logo-favicon.jpeg"

const JobExperience = () => {

  const [jobExperienceLength] = useState(jobExperience?.length)

  return (
    <Container fluid id="job-experience" className="py-5">
      <Container className="my-4 my-lg-5">
        <Row>
          <Col xs={12} className="text-center">
            <AnimationOnScroll
              animateIn="animate__fadeInDown"
              animateOnce={true}
            >
              <h2 className="h1 main-color">Experience</h2>
            </AnimationOnScroll>
          </Col>
        </Row>

        <Row>
          <Col xs={12} lg={12}>
            <div className="experienced_list">
                <ul className="noof_experience_list list-group">
                  { jobExperience?.map( (item, index) => {
                    return (
                      <li
                        key={`${btoa(item?.jobTitle)}_${index}`}
                        className="d-flex w-100 noof_experience_list_item_seperator"
                      >
                        <div>
                          <div className="noof_experience-entity py-3">
                            <div className="d-flex align-items-self">
                              <div className="me-3">
                                <AnimationOnScroll
                                  animateIn="animate__fadeIn"
                                  animateOnce={true}
                                >
                                  <Image
                                    src={item?.companyLogo}
                                    alt={item?.companyName}
                                    width={48}
                                    height={48}
                                    style={{
                                      objectFit: "cover",
                                      objectPosition: "center",
                                    }}
                                  />
                                </AnimationOnScroll>
                              </div>
                              <div className="d-flex flex-column w-100 align-self-center">
                                <div className="d-flex flex-row justify-content-between">
                                  <div className="d-flex flex-column w-100">
                                    <div className="d-flex flex-wrap align-items-center h-100">
                                      <div className="d-flex">
                                        <div className="d-flex w-100">
                                          <div className="d-flex align-items-center me-1 fw-bold">
                                            <span
                                              aria-hidden="true"
                                              style={{
                                                fontSize: "20px",
                                                fontFamily: "Aspira Medium",
                                              }}
                                            >
                                              <AnimationOnScroll
                                                animateIn="animate__fadeIn"
                                                animateOnce={true}
                                              >
                                                {item?.jobTitle}
                                              </AnimationOnScroll>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <AnimationOnScroll
                                      animateIn="animate__fadeIn"
                                      animateOnce={true}
                                      className='d-flex flex-column'
                                    >
                                      <span
                                        aria-hidden="true"
                                        style={{ fontSize: "15px" }}
                                        className="text-muted"
                                      >
                                        {`${item?.companyName}${
                                          item?.jobType
                                            ? ` . ${item?.jobType}`
                                            : ""
                                        }`}
                                      </span>
                                      <span
                                        aria-hidden="true"
                                        style={{ fontSize: "15px" }}
                                        className="text-muted"
                                      >
                                        {item?.jobStartDate}
                                        {item?.jobEndDate
                                          ? ` - ${item?.jobEndDate} . `
                                          : ""}
                                        {item?.jobTotalExperience}
                                      </span>
                                      <span
                                        aria-hidden="true"
                                        style={{ fontSize: "15px" }}
                                        className="text-muted"
                                      >
                                        {item?.jobLocation}
                                      </span>
                                    </AnimationOnScroll>
                                  </div>
                                </div>

                                <div className="noof_experience_list_outer_container">
                                  <ul className="noof_experience_list list-group">
                                    <li className="d-flex flex-column w-100">
                                      <div className="noof_experience_list_outer_container">
                                        <ul className="noof_experience_list list-group">
                                          <li className="noof_experience_list_item d-flex flex-column w-100 pt-2">
                                            <div className="d-flex">
                                              <div className="d-flex w-100">
                                                {item?.jobNestedList?.length ? (
                                                  <ul className="noof_experience_list list-group">
                                                    <li className="d-flex flex-column w-100">
                                                      <div className="noof_experience_list_outer_container">
                                                        <ul className="noof_experience_list list-group">
                                                          {item?.jobNestedList?.map(
                                                            (
                                                              nestedItem,
                                                              index
                                                            ) => (
                                                              <li
                                                                key={`${btoa(
                                                                  nestedItem?.jobTitle
                                                                )}_${index}`}
                                                                className="noof_experience_list_item d-flex flex-column w-100 pt-0 nested_experience"
                                                              >
                                                                <span className="path-node"></span>
                                                                <AnimationOnScroll
                                                                  animateIn="animate__fadeIn"
                                                                  animateOnce={
                                                                    true
                                                                  }
                                                                >
                                                                  <div className="d-flex">
                                                                    <div className="d-flex w-100">
                                                                      <div>
                                                                        <div className="noof_experience-entity py-3">
                                                                          <span className="path-line"></span>
                                                                          <div className="d-flex align-items-self">
                                                                            <div className="d-flex flex-column w-100 align-self-center">
                                                                              <div className="d-flex flex-row justify-content-between">
                                                                                <div className="d-flex flex-column w-100">
                                                                                  <div className="d-flex flex-wrap align-items-center h-100">
                                                                                    <div className="d-flex">
                                                                                      <div className="d-flex w-100">
                                                                                        <div className="d-flex align-items-center me-1 fw-bold">
                                                                                          <span
                                                                                            aria-hidden="true"
                                                                                            style={{
                                                                                              fontSize: 20,
                                                                                              fontFamily:
                                                                                                "Aspira Medium",
                                                                                            }}
                                                                                          >
                                                                                            {
                                                                                              nestedItem?.jobTitle
                                                                                            }
                                                                                          </span>
                                                                                        </div>
                                                                                      </div>
                                                                                    </div>
                                                                                  </div>
                                                                                  <span
                                                                                    aria-hidden="true"
                                                                                    className="text-muted"
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                    }}
                                                                                  >
                                                                                    {`${nestedItem?.jobStartDate} - ${nestedItem?.jobEndDate} . ${nestedItem?.jobTotalExperience}`}
                                                                                  </span>
                                                                                  <span
                                                                                    aria-hidden="true"
                                                                                    className="text-muted"
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                    }}
                                                                                  >
                                                                                    {
                                                                                      nestedItem?.jobLocation
                                                                                    }
                                                                                  </span>
                                                                                </div>
                                                                              </div>
                                                                              <div className="noof_experience_list_outer_container">
                                                                                <ul className="noof_experience_list list-group">
                                                                                  <li className="d-flex flex-column w-100">
                                                                                    <div className="noof_experience_list_outer_container">
                                                                                      <ul className="noof_experience_list list-group">
                                                                                        <li className="noof_experience_list_item d-flex flex-column w-100 pt-2">
                                                                                          <div className="d-flex">
                                                                                            <div className="d-flex w-100">
                                                                                              <div className="d-flex align-items-center text-black">
                                                                                                <div
                                                                                                  aria-hidden="true"
                                                                                                  style={{
                                                                                                    fontSize: 15,
                                                                                                  }}
                                                                                                  dangerouslySetInnerHTML={{
                                                                                                    __html:
                                                                                                      nestedItem?.jobDescription,
                                                                                                  }}
                                                                                                />
                                                                                              </div>
                                                                                            </div>
                                                                                          </div>
                                                                                        </li>
                                                                                      </ul>
                                                                                    </div>
                                                                                  </li>
                                                                                </ul>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </AnimationOnScroll>
                                                              </li>
                                                            )
                                                          )}
                                                        </ul>
                                                      </div>
                                                    </li>
                                                  </ul>
                                                ) : (
                                                  <AnimationOnScroll
                                                    animateIn="animate__fadeIn"
                                                    animateOnce={
                                                      true
                                                    }
                                                  >
                                                    <div className="d-flex align-items-center text-black">
                                                      <div
                                                        aria-hidden="true"
                                                        style={{ fontSize: 15 }}
                                                        dangerouslySetInnerHTML={{
                                                          __html:
                                                            item?.jobDescription,
                                                        }}
                                                      />
                                                    </div>
                                                  </AnimationOnScroll>
                                                )}
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default JobExperience