import React from 'react'
import "./index.css"
import { Image } from 'react-bootstrap';

const SocialIconList = ({ className, SocialMedia }) => {
  const ListItem = ({ icon, link }) => {
    return (
      <li className="list_item main-bg-color">
        {link ? (
          <a href={link} target="_blank" rel="noreferrer">
            <Image src={icon} fluid />
          </a>
        ) : (
          <Image src={icon} fluid />
        )}
      </li>
    );
  };

  return (
    <ul className={`socialItemList ${className}`}>
      {SocialMedia?.map((item, index) => (
        <ListItem key={index} icon={item.icon} link={item.link} />
      ))}
    </ul>
  );
};

export default SocialIconList;