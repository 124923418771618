import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { AnimationOnScroll } from "react-animation-on-scroll";
import { aboutme, aboutThumbnail } from '../constance/about.constant'

import HalfCircle from "../images/half_circle_vector.png"

const About = () => {
  return (
    <>
      <Container fluid>
        <Container className="position-relative ">
          <AnimationOnScroll animateIn="animate__fadeIn">
            <Image
              src={HalfCircle}
              className="position-absolute animate__animated animate__fadeIn"
              style={{ right: -80, top: 0 }}
            />
          </AnimationOnScroll>
        </Container>
      </Container>
      <Container fluid id="about" className="py-5">
        <Container>
          <Row className="my-4 my-lg-5 align-items-center flex-lg-row flex-column-reverse">
            <Col lg={7}>
              <AnimationOnScroll
                animateIn="animate__fadeInLeft"
                animateOnce={true}
              >
                <h2 className="about__title main-color  h1 mb-4 animate__animated animate__fadeInLeft">
                  {aboutme?.title}
                </h2>
              </AnimationOnScroll>

              <AnimationOnScroll
                animateIn="animate__fadeInLeft"
                animateOnce={true}
              >
                <div
                  className="about__content"
                  dangerouslySetInnerHTML={{ __html: aboutme?.content }}
                ></div>
              </AnimationOnScroll>
            </Col>
            <Col lg={1} />
            <Col md={5} lg={4} className="text-center">
              <AnimationOnScroll
                animateIn="animate__fadeInRight"
                animateOnce={true}
              >
                <Image
                  src={aboutThumbnail?.url}
                  fluid
                  alt={aboutThumbnail?.alt}
                  className="mb-4 mb-md-0"
                />
              </AnimationOnScroll>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}

export default About