import React, { useEffect } from 'react'
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap'
import { AnimationOnScroll } from 'react-animation-on-scroll'

import TaylorJanszen from "../images/taylor-janszen-portrait.png"
import RightSideArt from "../images/contact_bg_bottom.png"
import CurlyWave from "../images/curly_wave.png"

const GetInTouch = () => {

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
    script.onload = function (ev) {
      console.log("script loaded!!");
    };
    script.onerror = function (ev) {
      console.log("script failed to load." + ev);
    };
    document.body.appendChild(script);
  }, []);

  return (
    <Container fluid className="pt-5 main-bg-color" id="getintouch">
      <Container className="mt-4 mt-lg-5 position-relative z-1">
        <AnimationOnScroll animateIn="animate__fadeIn">
          <Image
            src={CurlyWave}
            alt="Left side art"
            style={{
              position: "absolute",
              left: -80,
              top: 0,
            }}
          />
        </AnimationOnScroll>
        <Row>
          <Col xs={12} className="text-center mb-4">
            <AnimationOnScroll
              animateIn="animate__fadeInDown"
              animateOnce={true}
            >
              <h2 className="h1 text-white">Schedule a Call</h2>
            </AnimationOnScroll>
          </Col>
        </Row>
        <Row className="py-0 position-relative z-1">
          <Col xs={12} lg={12}>
            <AnimationOnScroll animateIn="animate__fadeIn">
              <div
                className="meetings-iframe-container"
                data-src="https://meetings.hubspot.com/taylor-janszen?embed=true"
              ></div>
            </AnimationOnScroll>
          </Col>
        </Row>
      </Container>
      <AnimationOnScroll animateIn="animate__fadeIn" animateOnce={true}>
        <Image
          src={RightSideArt}
          alt="Right side art"
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            maxWidth: 200,
            zIndex: 0,
          }}
        />
      </AnimationOnScroll>
    </Container>
  );
}

export default GetInTouch