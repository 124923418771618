import React from 'react'
import { Col } from "react-bootstrap";

const Widget = ({ colSize, className, children }) => {
  return (
    <Col xs={12} md={6}  lg={colSize} className={className}>
      {children}
    </Col>
  );
};

export default Widget