import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { AnimationOnScroll } from "react-animation-on-scroll";

import MiamiUniversityLogo from "../images/miami_university_logo.png"
import FranciesDesale from "../images/francies_desales_high_school.png"

import CircleLines from "../images/half_circle_lines.png"
import CurlyLines from "../images/green_curly_lines.png"
import GooglePartner from "../images/google_partner.png"
import GoogleAdwards from "../images/google_adwords.png"
import GoogleAnalytics from "../images/google_analytics.png"
import BrightEdge from "../images/brightedgecertified.svg"

const Education = () => {

  const EducationItem = ({ degreeName, period, universityName, universityLogo, otherInfo }) => {
    return (
      <div className="education_item mb-5 text-center">
        {universityLogo && (
          <Image
            src={universityLogo}
            alt={universityName}
            fluid
            width={200}
            className="mb-4"
            style={{ maxHeight: 48 }}
          />
        )}
        <p className="title main-color mb-1">{degreeName}</p>
        <p className="title main-color mb-1">{period}</p>
        <p className="title main-color mb-2">{universityName}</p>
        {otherInfo &&
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: otherInfo }}
          ></div>
        }
      </div>
    );
  }


  return (
    <>
      <Container fluid>
        <Container className="position-relative">
          <AnimationOnScroll animateIn="animate__fadeIn">
            <Image
              src={CircleLines}
              className="position-absolute"
              style={{ left: -80, top: 0, transform: "rotate(-180deg)" }}
            />
          </AnimationOnScroll>
        </Container>
      </Container>
      <Container fluid className="py-5" id="education">
        <Container className="my-4 mb-2 my-lg-5 mb-lg-5">
          <Row>
            <Col xs={12} className="text-center">
              <AnimationOnScroll
                animateIn="animate__fadeInDown"
                animateOnce={true}
              >
                <h2 className="h1 main-color">
                  Education &amp; Certifications
                </h2>
              </AnimationOnScroll>
            </Col>
          </Row>

          <Row className="mt-5">
            <Col xs={12} lg={12}>
              <Row className="justify-content-center">
                <Col xs={12} lg={3}>
                  <AnimationOnScroll
                    animateIn="animate__fadeInUp"
                    animateOnce={true}
                  >
                    <EducationItem
                      degreeName="Graphic Design"
                      period="2005 - 2009"
                      universityName="St. Francis Desales H.S."
                      universityLogo={FranciesDesale}
                    />
                  </AnimationOnScroll>
                </Col>
                <Col xs={12} lg={3}>
                  <AnimationOnScroll
                    animateIn="animate__fadeInUp"
                    animateOnce={true}
                  >
                    <EducationItem
                      degreeName="Bachelor of Arts (B.A.)"
                      period="2009 - 2013"
                      universityName="Miami University"
                      universityLogo={MiamiUniversityLogo}
                    />
                  </AnimationOnScroll>
                </Col>
              </Row>
            </Col>
            <Col xs={12} lg={12}>
              <Row>
                <Col xs={6} md={3}>
                  <AnimationOnScroll
                    animateIn="animate__fadeInUp"
                    animateOnce={true}
                  >
                    <Image
                      src={GooglePartner}
                      fluid
                      alt="Google Certified Partner"
                      className="my-3"
                      style={{ width: 250 }}
                    />
                  </AnimationOnScroll>
                </Col>
                <Col xs={6} md={3}>
                  <AnimationOnScroll
                    animateIn="animate__fadeInUp"
                    animateOnce={true}
                  >
                    <Image
                      src={BrightEdge}
                      fluid
                      alt="BrightEdge Certification"
                      className="my-3"
                      style={{ width: 250 }}
                    />
                  </AnimationOnScroll>
                </Col>
                <Col xs={6} md={3}>
                  <AnimationOnScroll
                    animateIn="animate__fadeInUp"
                    animateOnce={true}
                  >
                    <Image
                      src={GoogleAdwards}
                      fluid
                      alt="Google Adwords Certified"
                      className="my-3"
                      style={{ width: 250 }}
                    />
                  </AnimationOnScroll>
                </Col>
                <Col xs={6} md={3}>
                  <AnimationOnScroll
                    animateIn="animate__fadeInUp"
                    animateOnce={true}
                  >
                    <Image
                      src={GoogleAnalytics}
                      fluid
                      alt="Google Analytics Certified"
                      className="my-3"
                      style={{ width: 250 }}
                    />
                  </AnimationOnScroll>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid>
        <Container className="position-relative">
          <AnimationOnScroll animateIn="animate__fadeIn">
            <Image
              src={CurlyLines}
              className="position-absolute"
              style={{ right: -80, bottom: 50 }}
            />
          </AnimationOnScroll>
        </Container>
      </Container>
    </>
  );
}

export default Education